// Dependencies
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
// Hooks & Helpers
import { useResetHeader } from "../hooks/useUpdateHeader"
// Components
import Layout from "../components/Layout"
import Image from "../components/Image"
import Filters, { useFilters } from "../components/Filters"
import RichText from "../components/RichText"

const Person = ({ slug, portrait, thumbnail, name, title, role }) => {

	const isFiltered = useFilters([role])

	const image = thumbnail || portrait

	return (
		isFiltered && (
			<Link to={`/people/${slug}`} className="person block p3">
				<div className="person__portrait">
					<Image image={image.crop} alt={name} />
				</div>
				<div className="outer">
					<h4>{name}</h4>	
					<p>{title}</p>
				</div>
			</Link>
		)
	)
}

const People = ({ data }) => {

	const { introduction, people, bodyContent } = data.allContentfulPeople.nodes[0];

	// Resets header colours back to black
	useResetHeader()

	return (
		<Layout headerTitle="People" hideLogo showFilterButton theme={{
			background: 'var(--red-bg)',
			highlight: 'var(--red)'
		}} title={`People | AFK`}>
			<div className="people header-offset">
				<div className="guttersx4 outerx4">
					<h1>{introduction.copy}</h1>
				</div>
				<div className="grid-3 guttersx4 outerx4">
					{people.map((person) => (
						<Person {...person} key={person.id} />
					))}
				</div>
				
				{bodyContent && (
					<article className="outerx4 p4 has-indents">
						<RichText content={bodyContent} />
					</article>
				)}
			</div>

			<Filters type="people" />
		</Layout>
	)
}

People.propTypes = {
  data: PropTypes.object.isRequired
}

export default People

export const PeoplePageQuery = graphql`
  query PeopleQuery {
		allContentfulPeople {
			nodes {
				introduction {
					copy: introduction
				}
				bodyContent {
					raw
					references {
						... on ContentfulGallery {
							...ContentfulGalleryFragment
						}	
					}
				}
				people {
					id
					name
					title
					slug
					thumbnail {
						file {
							contentType
							url
						}
						cover: gatsbyImageData(
							layout: FULL_WIDTH
						)
						crop: gatsbyImageData(
							layout: FULL_WIDTH
							aspectRatio: 1.33
						)
					}
					portrait {
						file {
							contentType
							url
						}
						cover: gatsbyImageData(
							layout: FULL_WIDTH
						)
						crop: gatsbyImageData(
							layout: FULL_WIDTH
							aspectRatio: 1.33
						)
					}
					role {
						slug
					}
				}
			}
		}
  }
`